import { Component, Input, OnChanges } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { startOfDay, endOfDay } from "date-fns";
import { Utils } from "@services/utils";
import { StringULID, UsTimezone } from "@wearewarp/types";
import { BaseForm, FormGroupDeclaration } from "@wearewarp/ng-form";
import { ApiService } from "@services/api.service";
import { Router } from "@angular/router";
import { FilterInfo } from "@wearewarp/types/data-model";
import { forkJoin } from "rxjs";

export interface FormDataFilter {
  name: string;
  status?: string;
  clientId?: StringULID;
  shipmentType?: string;
  carrierId?: StringULID;
  pickupState?: string;
  dropoffState?: string;
  vehicleType?: string;
  fromDate?: string;
  toDate?: string;
  fromTimezone?: UsTimezone,
  toTimezone?: UsTimezone,
  picId?: StringULID;
  carrierSaleRepId?: StringULID;
  clientSaleRepId?: StringULID;
}

export interface SelectData {
  loading: boolean;
  data: any[];
}

@Component({
  selector: "filter-info-modal",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class FilterInfoModal extends BaseForm<FormDataFilter> {
  @Input() data: FilterInfo;

  @Input() currentTap: number;
  @Input() urlMyLoad: any;
  @Input() page: any;
  @Input() search: any;
  @Input() onSave: (filterId: string, desc: string) => void;
  @Input() onApply: (filterData: any, desc: string) => void;
  @Input() isEdit = false;

  /** Select Options */
  listClients: SelectData = { loading: false, data: [] };
  listCarriers: SelectData = { loading: false, data: [] };
  listCarrierSales: SelectData = { loading: false, data: [] };
  listClientSales: SelectData = { loading: false, data: [] };
  listDispatchers: SelectData = { loading: false, data: [] };

  public listTimezones = DateUtil.listUsTimezones;

  listTimeToPickups = [
    { value: "6", label: "6 hours" },
    { value: "12", label: "12 hours" },
    { value: "18", label: "18 hours" },
    { value: "24", label: "24 hours" },
    { value: "48", label: "48 hours" },
    { value: "72", label: "72 hours" },
  ];

  countriesStates = MasterData.getCountriesStates_forSelectGroup();
  listShipmentTypes = Const.ShipmentTypesArray;
  listDispatcherForDropdown: any[] = [];
  dispatchersHaveJobs = [];
  showDispatcher = false;
  loading = false;

  constructor(private api: ApiService, private router: Router) {
    super();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Filter Name", required: true, placeHolder: "Enter filter name" },
    status: { label: "Status", placeHolder: "Select", notAcceptEmpty: true },
    clientId: { label: "Customer", notAcceptEmpty: true, placeHolder: "Select" },
    shipmentType: { label: "Shipment Type", placeHolder: "Select", notAcceptEmpty: true },
    carrierId: { label: "Carrier", notAcceptEmpty: true, placeHolder: "Select" },
    pickupState: { label: "Pickup State", placeHolder: "Select Pick", notAcceptEmpty: true },
    dropoffState: { label: "Delivery State", placeHolder: "Select Drop", notAcceptEmpty: true },
    // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
    vehicleType: { label: "Equipment", placeHolder: "Select", notAcceptEmpty: true },
    fromDate: { label: "From date", type: "date", notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
    toDate: { label: "To date", type: "date", notAcceptEmpty: true, getValue: DateUtil.getDateTo },
    fromTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    toTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    timeToPickup: { label: "Time to Pickup", placeHolder: "Select", notAcceptEmpty: true },
    picId: { label: "Dispatcher", placeHolder: "Select", notAcceptEmpty: true },
    carrierSaleRepId: { label: "Carrier Sales Rep", placeHolder: "Select", notAcceptEmpty: true },
    clientSaleRepId: { label: "Client Sales Rep", placeHolder: "Select", notAcceptEmpty: true },
    clientFilterType: {
      label: "Client Filter Type",
      placeHolder: "Select",
      notAcceptEmpty: true,
      initialValue: "include",
    },
  };

  ngOnInit(): void {
    super.ngOnInit();
    
    forkJoin([this.fetchListCarriers(), this.fetchListDispatchers(), this.fetchListCarrierSales(), this.fetchListClientSales()])
    .subscribe(([res1, res2, res3, res4]) => {
      this.listCarriers.loading = false;
      this.listDispatchers.loading = false;
      this.listCarrierSales.loading = false;
      this.listClientSales.loading = false;
      this.listCarriers.data = res1.data.list_data;
      this.listDispatchers.data = res2.data.list_data;
      this.listCarrierSales.data = res3.data.list_data;
      this.listClientSales.data = res4.data.list_data;

      this.getListDispatcherHaveJobAfterFilter();
      this.bindFormData(this.data?.data || this.data || {});
      this.setEnable(true);
    });
  }

  protected beforeBindModel(model): FormDataFilter {
    if (model.fromDate && model.fromTimezone) {
      let timezone = DateUtil.mapTimezoneUS(model.fromTimezone);
      const date = DateUtil.convertLocalTime2(model.fromDate, timezone).toISOString();
      model.fromDate = new Date(date);
    }
    if (model.toDate && model.toTimezone) {
      let timezone = DateUtil.mapTimezoneUS(model.toTimezone);
      const date = DateUtil.convertLocalTime2(model.toDate, timezone).toISOString();
      model.toDate = date;
    }
      return {
        name: model?.name || "",
        ...(model?.filterData || model || {}),
      }
  }

  get isTabAll() {
    return this.currentTap == 4;
  }

  private fetchListCarriers() {
    let url = `${Const.APIURI_CARRIERS}/list/all_for_filter`;
    this.listCarriers.loading = true;
    return this.api.GET(url);
  }

  private fetchListDispatchers(silent: boolean = false) {
    this.listDispatchers.loading = true;
    return this.api.getListDispatcherUsersFilter();
  }

  private fetchListClientSales(silent: boolean = false) {
    this.listClientSales.loading = true;
    return this.api.getListClientSalesRepUsersFilter();
  }

  private fetchListCarrierSales(silent: boolean = false) {
    this.listCarrierSales.loading = true;
    return this.api.getListAdminUsers();
  }

  getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? "";
  }

  getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? "";
  }
  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || "";
  }
  startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }
  getCarrierNameWithMCandDOT(carrier): string {
    if (carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
      return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`;
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`;
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`;
    return carrier?.basicInfo?.name ?? "";
  }
  getListDispatcherForDropdown() {
    let list = [...this.listDispatchers.data];
    return list
      .map((dispatcher) => {
        const item = this.dispatchersHaveJobs.find((i) => i._id == dispatcher.id);
        dispatcher.jobCount = item?.jobCount || 0;
        return dispatcher;
      })
      .filter((dispatcher) => dispatcher.jobCount);
  }
  dispatcherName(item) {
    let name: string = this.getFullName(item);
    if (item.jobCount) {
      name = `${name} (${item.jobCount})`;
    }
    return name;
  }
  getVehicleType() {
    const vehicleType = this.getItemValue("vehicleType");
    if (vehicleType) {
      return { code: vehicleType };
    }
    return null;
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = this.getFormData();
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPODChecked) {
      condition.isNeedPOD = true;
    }
    if (condition.sort) {
      q["sort"] = condition.sort;
      delete condition.sort;
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q["filter"] = f;
    }
    q["tab"] = Utils.parseQueryStringFromUrl(this.router.url)?.tab || Const.DispatchTab.all_load;
    return q;
  }

  getListDispatcherHaveJobAfterFilter() {
    this.listDispatcherForDropdown = this.listDispatcherForDropdown.map((dispatcher) => {
      dispatcher.jobCount = 0;
      return dispatcher;
    });
    const query = this.buildQuery();
    this.api.POST(`${Const.APIURI_JOBS}/count_job_for_dispatcher_list`, query).subscribe(
      (resp) => {
        this.dispatchersHaveJobs = resp.data.list_data;
        setTimeout(() => {
          this.listDispatcherForDropdown = this.getListDispatcherForDropdown();
        }, 100);
      },
      (err) => {
        Log.e(err);
      }
    );
  }

  onChange(event, key) {
    if (key == "vehicleType") {
      this.setItemValue(key, event?.code);
    }
    this.fetchListDispatchers();
  }

  onChangeCarrier(value, key) {
    if (value != "no") {
      this.needCarrierChecked = false;
    }
    if (value == "no") {
      this.needCarrierChecked = true;
      this.needPODChecked = false;
    }
  }

  onChangeDispatcher(value, key) {
    if (value != "no") {
      this.needDispatcherChecked = false;
    }
    if (value == "no") {
      this.needDispatcherChecked = true;
      this.needPODChecked = false;
    }
  }

  // "Need Dispatcher" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Dispatcher: has not assigned yet
  needDispatcherChecked = false;
  onCheckboxNeedDispatcher(event) {
    this.needPODChecked = false;
    if (event == true) {
      this.setItemValue("picId", "no");
    } else {
      this.setItemValue("picId", null);
    }
  }

  // "Need Carrier" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Carrier: has not assigned yet
  needCarrierChecked = false;
  onCheckboxNeedCarrier(event) {
    this.needPODChecked = false;
    if (event == true) {
      this.setItemValue("carrierId", "no");
    } else {
      this.setItemValue("carrierId", null);
    }
  }

  needPODChecked = false;
  onCheckboxNeedPOD(event) {
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    if (event == true) {
      if (this.getItemValue("picId") == "no") {
        this.setItemValue("picId", null);
      }
      if (this.getItemValue("carrierId") == "no") {
        this.setItemValue("carrierId", null);
      }
    }
  }

  onBtnReset() {
    for(let key of Object.keys(this.formInput.controls)) {
      this.setItemValue(key, null);
    }
  }

  isValid() {
    return this.formInput && this.validate() === null;
  }

  isChanged() {
    return this.formInput && this.isFormDataChanged();
  }

  onBtnSave() {
    if (!this.isValid()) return;
    this.loading = true;
    const data: FormDataFilter = this.getFormData();
    const { name, ...filterData } = data;
    const desc = this.buildPreviewInfo(filterData as any)
    if (this.data?.id) {
      const url = `${Const.APIURI_FILTER_INFO}/${this.data.id}`;
      this.api.PUT(url, { data: { name, filterData } }).subscribe(
        (resp) => {
          this.loading = false;
          this.onSave(this.data.id, desc);
        },
        (err) => {
          this.loading = false;
          Log.e(err);
        }
      );
    } else {
      const url = Const.APIURI_FILTER_INFO;
      this.api.POST(url, { source: "web-admin", screen: "carrier-sales", data: { name, filterData } }).subscribe(
        (resp) => {
          this.loading = false;
          this.onSave(resp.data.id, desc);
        },
        (err) => {
          this.loading = false;
          Log.e(err);
        }
      );
    }
  }
  getFormData(): FormDataFilter {
    const data = super.getFormData();
    if (data.fromDate && data.fromTimezone) {
      let timezone = DateUtil.mapTimezoneUS(data.fromTimezone);
      let date = DateUtil.toBeginOfDay(new Date(data.fromDate)).toISOString();
      data.fromDate = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
    }
    if (data.toDate && data.toTimezone) {
      let timezone = DateUtil.mapTimezoneUS(data.toTimezone);
      let date = DateUtil.toEndOfDay(new Date(data.toDate)).toISOString();
      data.toDate = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
    }
    return data;
  }

  onBtnApply() {
    // if (!this.isValid()) return;
    const filterData: FormDataFilter = this.getFormData();
    delete filterData.name;
    const desc = this.buildPreviewInfo(filterData);
    this.onApply(filterData, desc);
  }

  getFullName(user) {
    if (!user) return "";
    if (user.fullName) return user.fullName;
    let text = user.firstName || "";
    if (user.lastName) {
      if (text) {
        text += " ";
      }
      text += user.lastName;
    }
    return text;
  }

  setShowDispatcher() {
    if (this.urlMyLoad == "my_load") {
      return "my-load";
    } else {
      return "all-load";
    }
  }

  getStateDesc(state): string {
    let obj = state;
    if (typeof state == "string") {
      obj = MasterData.getStateUSByCode(state);
    }
    if (!obj) {
      return state;
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  getDescForValue(value: any, key: string) {
    switch (key) {
      case 'clientId': return this.listClients.data.filter(it => it.id == value)[0]?.name;
      case 'carrierId': return this.listCarriers.data.filter(it => it.id == value)[0]?.basicInfo?.name;
      case 'carrierSaleRepId': return this.getFullName(this.listCarrierSales.data.filter(it => it.id == value)[0]);
      case 'clientSaleRepId': return this.getFullName(this.listClientSales.data.filter(it => it._id == value)[0]);
      case 'fromDate':
      case 'toDate':
        return DateUtil.format(value, 'YYYY-MM-DD');
      default: return value;
    }
  }

  buildPreviewInfo(data: FormDataFilter): string {
    const info: string[] = [];
    for (let key of Object.keys(data)) {
      const label = this.getLabel(key);
      const value = this.getDescForValue(this.formInput.get(key).value, key);
      info.push(`${label}: ${value}`);
    }
    return info.length ? info.join("\n") : "";
  }
}
