<div class="filter-group">
  <nz-select nzAllowClear nzPlaceHolder="Saved Filters" nzShowSearch class="filter-select full-w" [nzSuffixIcon]="filterIcon" [ngModel]="selectedFilter" (ngModelChange)="onSelect($event)">
    <nz-option *ngFor="let filter of listFilters" nzCustomContent [nzValue]="filter.id" [nzLabel]="filter.data?.name">
      <i nz-icon nzType="pushpin" nzTheme="fill" *ngIf="filter.isDefault"></i>
      {{ filter.data?.name ?? "\<no-name\>"}}
    </nz-option>
  </nz-select>
  <ng-template #filterIcon>
    <i nz-icon nzType="filter" nzTheme="fill"></i>
  </ng-template>
  <!-- <button nz-button nzType="text" title="add" (click)="onBtnAdd()">
    <i nz-icon nzType="plus"></i>
  </button> -->
  <button nz-button nzType="text" title="edit" [disabled]="!canEdit()" (click)="onBtnEdit()">
    <i nz-icon nzType="edit"></i>
  </button>
  <button nz-button nzType="text" title="delete" [disabled]="!canEdit()" nzDanger (click)="onBtnDelete()">
    <i nz-icon nzType="delete"></i>
  </button>
  <a (click)="onOpenSearch()" nz-button nzType="link" *ngIf="!currentFilterDesc">
    All Filters
  </a>
  <a (click)="onOpenSearch()" nz-button nzType="link" *ngIf="currentFilterDesc"
    nz-popover
    nzPopoverTitle="Current filter"
    [nzPopoverContent]="tplCurrentFilterDesc">
    All Filters
    <i nz-icon nzType="filter" nzTheme="fill"></i>
    <ng-template #tplCurrentFilterDesc>
      <div style="white-space: pre-wrap;">{{currentFilterDesc}}</div>
    </ng-template>
  </a>
</div>
