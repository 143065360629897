import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Output } from "@angular/core";
import { FilterInfoModal } from "./filter-modal";
import { ModalHelper } from "@wearewarp/ng-antd";
import { Const } from "@const/Const";
import { FilterInfo } from "@wearewarp/types/data-model";

@Component({
  selector: "filter-info-select",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class FilterInfoSelectComponent extends BaseComponent {
  @Output() onFilterChange = new EventEmitter();

  selectedFilter: any;
  currentFilterDesc = "";
  currentFilterData: any;
  listFilters: FilterInfo[] = [];
  loading = false;
  defaultFilters: any[] = [
    {
      id: "default-1",
      isDefault: true,
      data: {
        name: "Walmart Only",
        filterData: { clientId: ['01H22NK3MBXBDCW6AZDWFZ09V0'], clientFilterType: "include" }
      }
    },
    {
      id: "default-2",
      isDefault: true,
      data: {
        name: "Exclude Walmart",
        filterData: { clientId: ['01H22NK3MBXBDCW6AZDWFZ09V0'], clientFilterType: "exclude" }
      }
    }
  ];
  constructor(private modalHelper: ModalHelper) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  loadData(id?: string) {
    this.loading = true;
    const url = `${Const.APIURI_FILTER_INFO}?source=web-admin&screen=carrier-sales`;
    this.api.GET(url).subscribe(
      (res: any) => {
        this.listFilters = [...this.defaultFilters, ...(res.data?.list_data || [])];
        if (id) {
          this.selectedFilter = id;
          this.onSelect(id);
          this.loading = false;
        }
      },
      (err) => {
        this.showErr(err);
        this.loading = false;
      }
    );
  }

  onSelect(filterId: any) {
    this.selectedFilter = filterId;
    const filter = this.listFilters.find((item) => item.id === filterId);
    this.currentFilterDesc = filter?.data?.name || "";
    this.currentFilterData = filter?.data?.filterData || {};
    this.onFilterChange.emit(filter?.data?.filterData || {});
  }

  onOpenSearch() {
    const modalRef = this.modalHelper.openForm(FilterInfoModal, {
      nzComponentParams: {
        data: this.currentFilterData || {},
        onSave: (filterId) => {
          this.loadData(filterId)
          modalRef.close();
        },
        onApply: (filterData, desc) => {
          this.onFilterChange.emit(filterData);
          this.currentFilterDesc = desc;
          this.currentFilterData = filterData;
          this.selectedFilter = null;
          modalRef.close();
        }
      },
      nzClassName: "send-message-form modal-xl",
      nzFooter: null,
    });
  }

  onBtnDelete() {
    this.loading = true;
    this.api.DELETE(`${Const.APIURI_FILTER_INFO}/${this.selectedFilter}`).subscribe(
      (res) => {
        this.loading = false;
        // this.showSuccess("Filter deleted successfully");
        this.selectedFilter = null;
        this.currentFilterDesc = "";
        this.currentFilterData = {};
        this.loadData();
        this.onFilterChange.emit({});
      },
      (err) => {
        this.loading = false;
        this.showErr(err);
      }
    );
  }

  onBtnEdit() {
    const modalRef = this.modalHelper.openForm(FilterInfoModal, {
      nzComponentParams: {
        data: this.listFilters.find((item) => item.id === this.selectedFilter),
        onSave: (filterId) => {
          this.loadData(filterId)
          modalRef.close();
        },
        isEdit: true,
      },
      nzClassName: "send-message-form modal-xl",
      nzFooter: null,
    });
  }

  canEdit() {
    return this.selectedFilter && !this.defaultFilters.find((item) => item.id === this.selectedFilter);
  }
}
